import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tenderService from "../../../services/tender";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Breadcrumbs,
  Box,
  FormControl,
  TextField,
} from "@mui/material";

export default function Tenders() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [tenders, setTenders] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      fetchTenders("", "", search);
    } else {
      fetchTenders(rowsPerPage, offset, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage, search]);

  const fetchTenders = async (limit, offset, search) => {
    try {
      const res = await tenderService.getTenders(limit, offset, search);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setTenders(data.tenders);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!tenders) {
    return <em>Loading...</em>;
  }

  const detail = (id) => {
    navigate(`/tender/${id}`);
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Tenders</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ m: 2, textAlign: "right" }}>
        <FormControl sx={{ width: 300 }}>
          <TextField
            id="search"
            label="Search by tender's name"
            type="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Category</TableCell>
              {/* <TableCell>Lowest Price</TableCell> */}
              <TableCell>End Date</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenders.map((row) => (
              <TableRow
                key={row.id}
                sx={
                  new Date(row.end_date).getTime() < new Date().getTime()
                    ? {
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: "#b1b1b1",
                      }
                    : {
                        "&:last-child td, &:last-child th": { border: 0 },
                      }
                }
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.topic.substring(0, 35) + "..."}</TableCell>
                <TableCell>{row.category}</TableCell>
                {/* <TableCell>{row.lowest_price}</TableCell> */}
                <TableCell>{row?.end_date.substring(0, 10)}</TableCell>
                <TableCell>{row?.createdAt.substring(0, 10)}</TableCell>
                <TableCell>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => detail(row.id)}
                  >
                    Detail
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!search && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
}
