import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  CardActions,
  Button,
  Modal,
  Paper,
  TableHead,
} from "@mui/material";
import UpdateAuth from "../../components/auth/UpdateAuth";
import dataService from "../../services/data";
import { instanceToken } from "../../utils/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  minHeight: "100vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const Dashboard = ({ user, updateUser, homeAlert }) => {
  const [tenders, setTenders] = useState(null);
  const [histories, setHistories] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [tenderCount, setTenderCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [activeTenderCount, setActiveTenderCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [contactCount, setContactCount] = useState(0);
  const [newsCount, setNewsCount] = useState(0);
  const [projectCount, setProjectCount] = useState(0);
  const [expiredTenderCount, setNotExpiredTenderCount] = useState(0);

  useEffect(() => {
    if (user.role === "tender") {
      fetchData();
    } else if (user.role === "website") {
      fetchWebsiteData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      const res = await dataService.getTenderData(instanceToken.token);
      setTenders(res.data.tenders);
      setHistories(res.data.histories);
      setNotExpiredTenderCount(res.data.expiredTenderCount);
      setTenderCount(res.data.tenderCount);
      setUserCount(res.data.userCount);
      setActiveTenderCount(res.data.activeTenderCount);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  // const fetchRecentlyAppliers = async () => {
  //   try {
  //     const res = await dataService.getRecentlyAppliers(instanceToken.token);
  //     setHistories(res.data);
  //   } catch (error) {
  //     console.error(error);
  //     throw new Error(error);
  //   }
  // }

  const fetchWebsiteData = async () => {
    const res = await dataService.getWebsiteData(instanceToken.token);
    setContacts(res.data.contacts);
    setServiceCount(res.data.serviceCount);
    setContactCount(res.data.contactCount);
    setNewsCount(res.data.newsCount);
    setProjectCount(res.data.projectCount);
  };

  if (!user.role) {
    return <em>Loading...</em>;
  }

  if (user.role === "website") {
    if (!contacts) {
      return <em>Loading...</em>;
    }
  } else if (user.role === "tender") {
    if (!tenders || !histories) {
      return <em>Loading...</em>;
    }
  }

  return (
    <div>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <span>Dashboard</span>
        </Breadcrumbs>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            my: 1,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
          }}
        >
          {user.role === "tender" && (
            <>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "1rem",
                }}
              >
                <Card
                  className="order-card order-card-2"
                  sx={{
                    flex: 1,
                    minHeight: "100%",
                    color: "black",
                    mr: 2,
                    p: 1,
                  }}
                >
                  <div className="card-bg-hover card-bg-2"></div>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ my: 0, mx: 2, zIndex: 2 }}
                  >
                    Total Tenders
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      my: 0,
                      mx: 2,
                      textAlign: "end",
                      fontWeight: "bolder",
                      zIndex: 2,
                    }}
                  >
                    {tenderCount}
                  </Typography>
                </Card>
                <Card
                  className="order-card order-card-2"
                  sx={{
                    flex: 1,
                    minHeight: "100%",
                    color: "black",
                    mr: 2,
                    p: 1,
                  }}
                >
                  <div className="card-bg-hover card-bg-2"></div>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ my: 0, mx: 2, zIndex: 2 }}
                  >
                    Active Tenders
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      my: 0,
                      mx: 2,
                      textAlign: "end",
                      fontWeight: "bolder",
                      zIndex: 2,
                    }}
                  >
                    {activeTenderCount}
                  </Typography>
                </Card>
                <Card
                  className="order-card order-card-2"
                  sx={{
                    flex: 1,
                    minHeight: "100%",
                    color: "black",
                    mr: 2,
                    p: 1,
                  }}
                >
                  <div className="card-bg-hover card-bg-2"></div>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ my: 0, mx: 2, zIndex: 2 }}
                  >
                    Expired Tenders
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      my: 0,
                      mx: 2,
                      textAlign: "end",
                      fontWeight: "bolder",
                      zIndex: 2,
                    }}
                  >
                    {expiredTenderCount}
                  </Typography>
                </Card>
                <Card
                  className="order-card order-card-2"
                  sx={{
                    flex: 1,
                    minHeight: "100%",
                    color: "black",
                    mr: 2,
                    p: 1,
                  }}
                >
                  <div className="card-bg-hover card-bg-2"></div>
                  <Typography
                    variant="h6"
                    component="h4"
                    sx={{ my: 0, mx: 2, zIndex: 2 }}
                  >
                    Total Users
                  </Typography>
                  <Typography
                    variant="h2"
                    component="h1"
                    sx={{
                      my: 0,
                      mx: 2,
                      textAlign: "end",
                      fontWeight: "bolder",
                      zIndex: 2,
                    }}
                  >
                    {userCount}
                  </Typography>
                </Card>
              </Box>
              <Box>
                <Box sx={{ flex: 4 }}>
                  <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
                    Recently Applier
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 550 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>User Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Tender Name</TableCell>
                          <TableCell>Created On</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {histories.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={
                              new Date(row.createdAt).getDate() ===
                              new Date().getDate()
                                ? {
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                      background: "#b1b1b1",
                                    },
                                  }
                                : {
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }
                            }
                          >
                            <TableCell component="th" scope="row">
                              {row.id}
                            </TableCell>
                            <TableCell>{row.user.name}</TableCell>
                            <TableCell>{row.user.email}</TableCell>
                            <TableCell>
                              <Link to={`/tender/${row.tender.id}`}>
                                {row.tender.name}
                              </Link>
                            </TableCell>
                            <TableCell>
                              {row?.createdAt.substring(0, 10)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </>
          )}
          {user.role === "website" && (
            <>
              <Card
                className="order-card order-card-2"
                sx={{ flex: 1, minHeight: "100%", color: "black", mr: 2, p: 1 }}
              >
                <div className="card-bg-hover card-bg-2"></div>
                <Typography
                  variant="h6"
                  component="h4"
                  sx={{ my: 0, mx: 2, zIndex: 2 }}
                >
                  Total Service
                </Typography>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    my: 0,
                    mx: 2,
                    textAlign: "end",
                    fontWeight: "bolder",
                    zIndex: 2,
                  }}
                >
                  {serviceCount}
                </Typography>
              </Card>
              <Card
                className="order-card order-card-2"
                sx={{ flex: 1, minHeight: "100%", color: "black", mr: 2, p: 1 }}
              >
                <div className="card-bg-hover card-bg-2"></div>
                <Typography
                  variant="h6"
                  component="h4"
                  sx={{ my: 0, mx: 2, zIndex: 2 }}
                >
                  Total Contact
                </Typography>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    my: 0,
                    mx: 2,
                    textAlign: "end",
                    fontWeight: "bolder",
                    zIndex: 2,
                  }}
                >
                  {contactCount}
                </Typography>
              </Card>
              <Card
                className="order-card order-card-2"
                sx={{ flex: 1, minHeight: "100%", color: "black", mr: 2, p: 1 }}
              >
                <div className="card-bg-hover card-bg-2"></div>
                <Typography
                  variant="h6"
                  component="h4"
                  sx={{ my: 0, mx: 2, zIndex: 2 }}
                >
                  Total News
                </Typography>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    my: 0,
                    mx: 2,
                    textAlign: "end",
                    fontWeight: "bolder",
                    zIndex: 2,
                  }}
                >
                  {newsCount}
                </Typography>
              </Card>
              <Card
                className="order-card order-card-2"
                sx={{ flex: 1, minHeight: "100%", color: "black", mr: 2, p: 1 }}
              >
                <div className="card-bg-hover card-bg-2"></div>
                <Typography
                  variant="h6"
                  component="h4"
                  sx={{ my: 0, mx: 2, zIndex: 2 }}
                >
                  Total Project
                </Typography>
                <Typography
                  variant="h2"
                  component="h1"
                  sx={{
                    my: 0,
                    mx: 2,
                    textAlign: "end",
                    fontWeight: "bolder",
                    zIndex: 2,
                  }}
                >
                  {projectCount}
                </Typography>
              </Card>
            </>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 5 }}>
          {user.role === "website" && (
            <Box sx={{ flex: 4 }}>
              <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
                Newest enquiry
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Interested Service</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Company Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>CreatedAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contacts.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.interestedService}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.phone}</TableCell>
                        <TableCell>{row?.createdAt.substring(0, 10)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {user.role === "tender" && (
            <Box sx={{ flex: 4 }}>
              <Typography variant="h6" component="h3" sx={{ mb: 1 }}>
                Active and Unexpired Tenders
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 550 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Topic</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Lowest Price</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Created On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tenders.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Link to={`/tender/${row.id}`}>{row.id}</Link>
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.topic.substring(0, 35) + "..."}
                        </TableCell>
                        <TableCell>{row.category}</TableCell>
                        <TableCell>{row.lowest_price}</TableCell>
                        <TableCell>{row?.end_date.substring(0, 10)}</TableCell>
                        <TableCell>{row?.createdAt.substring(0, 10)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Card sx={{ p: 2, flex: 1, ml: 4 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography variant="h5" component="h5">
                Profile
              </Typography>
              <TableContainer sx={{ mt: 4 }}>
                <Table
                  sx={{ minWidth: 100 }}
                  aria-label="user information table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Username
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="right">
                        {user.username}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Role
                      </TableCell>
                      <TableCell style={{ width: 160 }} align="right">
                        {user.role}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <Button onClick={() => handleOpenModal(true)} size="small">
                Edit
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Box>

      <div style={{ minHeight: "auto" }}>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <UpdateAuth
              userAlert={homeAlert}
              handleClose={handleCloseModal}
              user={user}
              updateData={updateUser}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Dashboard;
