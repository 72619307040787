import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getServices = async (token, limit, offset) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/services?limit=${limit}&offset=${offset}`,
    config
  );
  return response;
};

const getService = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/services/${id}`, config);
  return response;
};

const putService = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/services/${id}`,
    data,
    config
  );
  return response;
};

const postService = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(
    `${BACKEND_URL}/api/services`,
    data,
    config
  );
  return response;
};

const deleteService = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/services/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getServices,
  putService,
  postService,
  deleteService,
  getService,
};
