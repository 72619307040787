import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import userService from "../../../services/user";
import { instanceToken } from "../../../utils/constant";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function Users() {
  //   const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  useEffect(() => {
    fetchUsers(rowsPerPage, offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage]);

  const fetchUsers = async (limit, offset) => {
    try {
      const res = await userService.getUsers(
        instanceToken.token,
        limit,
        offset
      );
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setUsers(data.users);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!users) {
    return <em>Loading...</em>;
  }

  //   const detail = (id) => {
  //     navigate(`/user/${id}`);
  //   };

  const handleUpdate = async (user) => {
    try {
      setLoading(true);
      const data = {
        active: !user.active,
      };
      const res = await userService.putUser(instanceToken.token, user.id, data);
      setShowAlert({
        message: `User "${user.name}" have been ${
          user.active ? "Deactive" : "Activate"
        }.`,
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
      setUsers(users.map((u) => (u.id === user.id ? res.data : u)));
    } catch (error) {
      console.error(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Users</span>
        </Breadcrumbs>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Submitted Tenders</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow
                key={row.id}
                sx={
                  row.active
                    ? {
                        "&:last-child td, &:last-child th": { border: 0 },
                      }
                    : {
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: "#b1b1b1",
                      }
                }
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.company_name}</TableCell>
                <TableCell>{row.tendersCount}</TableCell>
                <TableCell>{row?.created_at?.substring(0, 10)}</TableCell>
                <TableCell>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={() => handleUpdate(row)}
                  >
                    {row.active ? "Deactive" : "Activate"}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "absolute", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "absolute", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
