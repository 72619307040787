import React, { useEffect, useState } from "react";
import bannerService from "../../../services/banner";
import { BACKEND_URL, instanceToken } from "../../../utils/constant";
import uploadService from "../../../services/upload";

import {
  Button,
  Breadcrumbs,
  Modal,
  Typography,
  Box,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  FormControl,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

export default function Banners({ homeAlert }) {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState(null);
  const [openR, setOpenR] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [errorPicture, setErrorPicture] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetchBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanners = async (limit, offset) => {
    try {
      const res = await bannerService.getBanners(
        instanceToken.token,
        limit,
        offset
      );
      setBanners(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!banners) {
    return <em>Loading...</em>;
  }

  const handleOpenR = (data) => {
    setData(data);
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemove = async (data) => {
    try {
      if (data.picture) {
        const filename = data.picture.substring(
          data.picture.lastIndexOf("/") + 1
        );
        await uploadService.deleteImage(instanceToken.token, {
          filename: filename,
        });
      }
      await bannerService.deleteBanner(instanceToken.token, data.id);
      homeAlert("Banner have been removed.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrorPicture("Please select image. (PNG, JPG, JPEG, GIF, ...)");
        return;
      }
      if (img.size > 10485760) {
        setErrorPicture("Image file size must be smaller than 10MB.");
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImageFile(img);
    }
  };

  // const handleImgUpload = async (url, imageFile) => {
  //   try {
  //     await uploadService.uploadImage(url, imageFile);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const fetchImageUrl = async () => {
  //   try {
  //     const res = await uploadService.getImageUrl(instanceToken.token);
  //     if (res.data) {
  //       return {
  //         url: res.data.imageUploadUrl,
  //         name: `https://axra.sgp1.digitaloceanspaces.com/Vesta/${res.data.imageName}`,
  //       };
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleImgUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("image", imageFile);
      const response = await uploadService.uploadImage(
        instanceToken.token,
        formData
      );
      return response.data.filename;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    setErrorPicture("");
    try {
      setLoading(true);
      const filename = await handleImgUpload();
      let data = { picture: `${BACKEND_URL}/static/images/${filename}` };
      await bannerService.postBanner(instanceToken.token, data);
      setPreview(null);
      homeAlert("Banner have been created.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server", true);
    } finally {
      setImageFile(null);
      setPreview(null);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Banners</span>
        </Breadcrumbs>
        <Button sx={{ my: 3 }} variant="contained" onClick={handleOpen}>
          Create
        </Button>
      </div>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        {banners.map((row, index) => (
          <Card sx={{ maxWidth: 385 }} key={index}>
            <CardMedia component="img" height="194" image={row.picture} />
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography gutterBottom variant="span" component="span">
                ID : {row.id}
              </Typography>
              <Typography gutterBottom variant="span" component="span">
                Created On : {row.createdAt.substring(0, 10)}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                color="error"
                size="small"
                onClick={() => handleOpenR(row)}
              >
                Remove
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove(data)}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={open}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Create new banner
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ my: 2 }}>
              <img
                src={preview}
                alt=""
                style={{ width: "100%", height: "270px", objectFit: "contain" }}
              />
            </Box>

            <FormControl sx={{ m: 2 }} variant="outlined">
              <TextField
                id="image"
                placeholder="Upload image"
                InputLabelProps={{ shrink: true }}
                label="Upload Image"
                onChange={imgFileSelect}
                error={errorPicture ? true : false}
                helperText={errorPicture}
                type="file"
                accept={imgFileTypes}
              />
            </FormControl>
          </Box>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
