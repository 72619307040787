import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const uploadImage = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/image`,
    formData,
    config
  );
  return response;
};

const uploadImages = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/images`,
    formData,
    config
  );
  return response;
};

const uploadFiles = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/files`,
    formData,
    config
  );
  return response;
};

const uploadFile = async (token, formData) => {
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const response = axios.post(
    `${BACKEND_URL}/api/upload/file`,
    formData,
    config
  );
  return response;
};

const deleteImage = async (token, data) => {
  const response = await axios.delete(`${BACKEND_URL}/api/upload/image`, {
    headers: { authorization: `Bearer ${token}` },
    data,
  });
  return response;
};

const deleteImages = async (token, data) => {
  const response = await axios.delete(`${BACKEND_URL}/api/upload/images`, {
    headers: { authorization: `Bearer ${token}` },
    data,
  });
  return response;
};

const deleteFile = async (token, data) => {
  const response = await axios.delete(`${BACKEND_URL}/api/upload/file`, {
    headers: { authorization: `Bearer ${token}` },
    data,
  });
  return response;
};

const deleteFiles = async (token, data) => {
  const response = await axios.delete(`${BACKEND_URL}/api/upload/files`, {
    headers: { authorization: `Bearer ${token}` },
    data,
  });
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadImage,
  uploadImages,
  uploadFiles,
  deleteImage,
  deleteImages,
  uploadFile,
  deleteFile,
  deleteFiles,
};

// const getImageUrl = async (token) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = axios.get(`${BACKEND_URL}/api/upload/image`, config);
//   return response;
// };

// const getFileUrl = async (token, name) => {
//   const config = { headers: { authorization: `Bearer ${token}` } };
//   const response = axios.get(`${BACKEND_URL}/api/upload/file/${name}`, config);
//   return response;
// };

// const uploadImage = async (uri, data) => {
//   const config = {
//     headers: {
//       "Content-Type": "image/*",
//       "x-amz-acl": "public-read",
//     },
//   };
//   const response = await axios.put(uri, data, config);
//   return response;
// };

// const uploadFile = async (uri, data) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/pdf",
//       "x-amz-acl": "public-read",
//     },
//   };
//   const response = await axios.put(uri, data, config);
//   return response;
// };

// const deleteImage = async (token, data) => {
//   const response = await axios.delete(`${BACKEND_URL}/api/upload/image`, {
//     headers: { authorization: `Bearer ${token}` },
//     data,
//   });
//   return response;
// };

// const deleteFile = async (token, data) => {
//   const response = await axios.delete(`${BACKEND_URL}/api/upload/file`, {
//     headers: { authorization: `Bearer ${token}` },
//     data,
//   });
//   return response;
// };

// // eslint-disable-next-line import/no-anonymous-default-export
// export default {
//   uploadImage,
//   deleteImage,
//   getImageUrl,
//   getFileUrl,
//   uploadFile,
//   deleteFile,
// };
