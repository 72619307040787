import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getTender = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/tenders/${id}`, config);
  return response;
};

const getTenders = async (limit, offset, search) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/tenders?limit=${limit}&offset=${offset}&search=${search}`
  );
  return response;
};

const getMinTenders = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/tenders`);
  return response;
};

const postTender = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/tenders`, data, config);
  return response;
};

const putTender = async (token, id, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.put(
    `${BACKEND_URL}/api/tenders/${id}`,
    data,
    config
  );
  return response;
};

const deleteTender = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/tenders/${id}`,
    config
  );
  return response;
};

const deleteUser = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/histories/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTender,
  getTenders,
  getMinTenders,
  postTender,
  putTender,
  deleteTender,
  deleteUser,
};
