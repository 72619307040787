import React, { useState } from "react";
import newsService from "../../../services/news";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Typography,
  FormControl,
  TextField,
  CardMedia,
  // Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { instanceToken, BACKEND_URL } from "../../../utils/constant";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];

const CreateNews = ({ homeAlert, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);

  const [values, setValues] = useState({
    title: "",
    description: "",
    picture: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      if (!imgFileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          profile: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setPreview(URL.createObjectURL(img));
      setImageFile(img);
      setIsImageChange(true);
    }
  };

  const handleImgUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("image", imageFile);
      const response = await uploadService.uploadImage(
        instanceToken.token,
        formData
      );
      return response.data.filename;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.title) {
      err.title = "Title field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;
      if (isImageChange) {
        const filename = await handleImgUpload();
        data = { ...data, picture: `${BACKEND_URL}/static/images/${filename}` };
      }
      await newsService.postNews(instanceToken.token, data);
      setValues({
        title: "",
        description: "",
        picture: "",
      });
      setPreview(null);
      homeAlert("New news have been created.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server", true);
    } finally {
      setLoading(false);
      setErrors({});
      handleClose();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: 300 }}>
          <CardMedia component="img" height="294" image={preview} />
          <CardContent>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Create news
            </Typography>
            <FormControl sx={{ m: 2, width: 450 }} variant="outlined">
              <TextField
                id="title"
                label="Title"
                value={values.title}
                onChange={handleChange("title")}
                error={errors.title ? true : false}
                helperText={errors.title}
              />
            </FormControl>
            <FormControl sx={{ m: 2, width: 450 }} variant="outlined">
              <TextField
                id="description"
                label="Description"
                multiline
                value={values.description}
                onChange={handleChange("description")}
                error={errors.description ? true : false}
                helperText={errors.description}
              />
            </FormControl>
            <FormControl sx={{ m: 2, width: 450 }} variant="outlined">
              <TextField
                id="image"
                placeholder="Upload image"
                InputLabelProps={{ shrink: true }}
                label="Upload Image"
                onChange={imgFileSelect}
                error={errors.picture ? true : false}
                helperText={errors.picture}
                type="file"
                accept={imgFileTypes}
              />
            </FormControl>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            {/* <Button color="secondary" onClick={() => handleClose(null)}>
              Cancel
            </Button> */}
            <LoadingButton
              loading={loading}
              onClick={handleClose}
              color="secondary"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default CreateNews;
