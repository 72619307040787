import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const login = async (credentials) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/login/auth`,
    credentials
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
};
