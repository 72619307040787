import React from "react";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";
import List from "@mui/material/List";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PeopleIcon from "@mui/icons-material/People";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import TitleIcon from "@mui/icons-material/Title";
import AddIcon from "@mui/icons-material/Add";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ContactsIcon from "@mui/icons-material/Contacts";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
let activeStyle = {
  textDecoration: "underline",
};

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({ handleDrawerClose, open, auth }) => {
  const theme = useTheme();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ overflow: "auto" }}>
        <Divider />
        <List>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to="/dashboard"
            className="nav-link"
          >
            <ListItem disablePadding className="nav-btn">
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon className="nav-link-icon" />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </List>
        {auth.role === "tender" && (
          <>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/tenders"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <TitleIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Tenders"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/createTender"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <AddIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Create New Tender"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/admins"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Admins"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/createAdmin"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Create Admin"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
          </>
        )}

        {auth.role === "website" && (
          <>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/services"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <RoomServiceIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Services"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/createService"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <AddCircleIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Create Service"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/projects"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountTreeIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Projects"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/createProject"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <AddCircleIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Create Project"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/news"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <NewspaperIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"News"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/contacts"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <ContactsIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Enquiry"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
            <Divider />
            <List>
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/banners"
                className="nav-link"
              >
                <ListItem disablePadding className="nav-btn">
                  <ListItemButton>
                    <ListItemIcon>
                      <ViewCarouselIcon className="nav-link-icon" />
                    </ListItemIcon>
                    <ListItemText primary={"Banner"} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
          </>
        )}

        <Divider />
        <List>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to="/users"
            className="nav-link"
          >
            <ListItem disablePadding className="nav-btn">
              <ListItemButton>
                <ListItemIcon>
                  <PeopleIcon className="nav-link-icon" />
                </ListItemIcon>
                <ListItemText primary={"Users"} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
