import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import tenderService from "../../../services/tender";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Alert,
  Button,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LoadingButton } from "@mui/lab";
import RichTextEditor from "react-rte";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { instanceToken, BACKEND_URL } from "../../../utils/constant";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const fileTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const UpdateTender = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [imageFiles, setImageFiles] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);
  const [file, setFile] = useState(null);
  const [ndaFile, setNdaFile] = useState(null);
  const [isFileChange, setIsFileChange] = useState(false);
  const [isNdaFileChange, setIsNdaFileChange] = useState(false);

  const [values, setValues] = useState({
    name: "",
    topic: "",
    category: "",
    lowest_price: "",
    start_date: "",
    end_date: "",
    interviewing_date: "",
    announcement_date: "",
    pictures: "",
    body: "",
    document_url: "",
    nda_url: "",
  });
  const [errors, setErrors] = useState({});
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());

  const [tender, setTender] = useState(null);
  const [oldImageNames, setOldImageNames] = useState([]);
  const [oldFileName, setOldFileName] = useState(null);
  const [oldNdaFileName, setOldNdaFileName] = useState(null);
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    if (!tender) {
      fetchTender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tender) {
      setValues({
        name: tender.name ?? "",
        topic: tender.topic ?? "",
        category: tender.category ?? "",
        lowest_price: tender.lowest_price ?? "",
        start_date: tender.start_date ?? "",
        end_date: tender.end_date ?? "",
        interviewing_date: tender.interviewing_date ?? "",
        announcement_date: tender.announcement_date ?? "",
        pictures: tender.pictures ? tender.pictures.split("||") : [],
        document_url: tender.document_url ?? "",
        nda_url: tender.nda_url ?? "",
        body: tender.body ?? "",
      });
      setTextValue(
        RichTextEditor.createValueFromString(tender.body ?? "", "html")
      );
      let image_urls = tender.pictures ? tender.pictures.split("||") : [];
      setPreviews(image_urls);
      if (image_urls) {
        const image_names = [];
        for (let image_url of image_urls) {
          image_names.push(
            image_url.substring(
              image_url.lastIndexOf("/") + 1,
              image_url.length
            )
          );
        }
        setOldImageNames(image_names);
      }
      let file_url = tender.document_url;
      if (file_url) {
        setOldFileName(
          file_url.substring(file_url.lastIndexOf("/") + 1, file_url.length)
        );
      }
      let nda_url = tender.nda_url;
      if (nda_url) {
        setOldNdaFileName(
          nda_url.substring(nda_url.lastIndexOf("/") + 1, nda_url.length)
        );
      }
    }
  }, [tender]);

  const fetchTender = async () => {
    try {
      const res = await tenderService.getTender(instanceToken.token, id);
      setTender(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!tender) {
    return <em>Loading...</em>;
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeText = (value) => {
    setTextValue(value);
    setValues({ ...values, body: value.toString("html") });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const files = [];
      const preview = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        preview.push(URL.createObjectURL(img));
        setIsImageChange(true);
        files.push(img);
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const fileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      if (!fileTypes.includes(file.type)) {
        setErrors({
          ...errors,
          document_url: "Please select PDF or DOCX file",
        });
        return;
      }
      if (file.size > 10485760 * 5) {
        setErrors({
          ...errors,
          document_url: "File size must be smaller than 50MB.",
        });
        return;
      }
      setIsFileChange(true);
      setFile(file);
    }
  };

  const ndaFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      if (!fileTypes.includes(file.type)) {
        setErrors({
          ...errors,
          nda_url: "Please select PDF or DOCX file",
        });
        return;
      }
      if (file.size > 10485760 * 5) {
        setErrors({
          ...errors,
          nda_url: "File size must be smaller than 50MB.",
        });
        return;
      }
      setIsNdaFileChange(true);
      setNdaFile(file);
    }
  };

  const deleteFile = async (filename) => {
    try {
      await uploadService.deleteFile(instanceToken.token, {
        filename: filename,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMultiImgUpload = async (formData) => {
    try {
      const response = await uploadService.uploadImages(
        instanceToken.token,
        formData
      );
      return response.data.filenames;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await uploadService.uploadFile(
        instanceToken.token,
        formData
      );
      return response.data.filename;
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async () => {
    setErrors({});
    let err = {};
    if (!values.name) {
      err.name = "Name field is required";
    }
    if (!values.topic) {
      err.topic = "Topic field is required";
    }
    if (!values.category) {
      err.category = "Category field is required";
    }
    // if (!values.lowest_price) {
    //   err.lowest_price = "Lowest Price field is required";
    // }
    // if (isNaN(values.lowest_price)) {
    //   err.lowest_price = "Lowest Price must be number";
    // }
    if (!values.start_date) {
      err.start_date = "Start date field is required";
    }
    if (!values.end_date) {
      err.end_date = "End date field is required";
    }
    if (!values.interviewing_date) {
      err.interviewing_date = "Interviewing Date field is required";
    }
    if (!values.announcement_date) {
      err.announcement_date = "Announcement Date field is required";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let response;
      let data = values;

      if (isImageChange) {
        if (oldImageNames.length > 0) {
          await uploadService.deleteImages(instanceToken.token, {
            filenames: oldImageNames,
          });
        }
        const formData = new FormData();
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          formData.append("images", img);
        }
        let filenames = await handleMultiImgUpload(formData);
        filenames = filenames.map(
          (filename) => `${BACKEND_URL}/static/images/${filename}`
        );
        const names = filenames.join("||");
        data = { ...data, pictures: names };
      } else {
        data = { ...data, pictures: tender.pictures };
      }

      if (isFileChange) {
        if (oldFileName) {
          await deleteFile(oldFileName);
        }
        const filename = await handleFileUpload(file);
        data = {
          ...data,
          document_url: `${BACKEND_URL}/static/files/${filename}`,
        };
      }

      if (isNdaFileChange) {
        if (oldNdaFileName) {
          await deleteFile(oldNdaFileName);
        }
        const filename = await handleFileUpload(ndaFile);
        data = {
          ...data,
          nda_url: `${BACKEND_URL}/static/files/${filename}`,
        };
      }

      data = { ...data, lowest_price: 0 };

      response = await tenderService.putTender(
        instanceToken.token,
        tender.id,
        data
      );
      setTender(response.data);
      setShowAlert({
        message: "Tender have been updated.",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/tenders">Tenders</Link>
          <Link to={`/tender/${id}`}>Tender (ID - {id})</Link>
          <span>Update</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: 800 }}>
          <CardContent sx={{ display: "flex" }}>
            <Box sx={{ flex: 2, display: "flex", flexDirection: "column" }}>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange("name")}
                  error={errors.name ? true : false}
                  helperText={errors.name}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="topic"
                  multiline
                  label="Project"
                  value={values.topic}
                  onChange={handleChange("topic")}
                  error={errors.topic ? true : false}
                  helperText={errors.topic}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="category"
                  label="Category"
                  value={values.category}
                  onChange={handleChange("category")}
                  error={errors.category ? true : false}
                  helperText={errors.category}
                />
              </FormControl>
              {/* <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="lowest_price"
                  label="Lowest Price"
                  type="tel"
                  value={values.lowest_price}
                  onChange={handleChange("lowest_price")}
                  error={errors.lowest_price ? true : false}
                  helperText={errors.lowest_price}
                />
              </FormControl> */}
              <FormControl sx={{ m: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date Of Posting"
                    value={values.start_date}
                    onChange={(newValue) => {
                      setValues({ ...values, start_date: newValue });
                    }}
                    error={errors.start_date ? true : false}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errors.start_date && (
                    <FormHelperText error>{errors.start_date}</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Last Date Of Submission"
                    value={values.end_date}
                    onChange={(newValue) => {
                      setValues({ ...values, end_date: newValue });
                    }}
                    error={errors.end_date ? true : false}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errors.end_date && (
                    <FormHelperText error>{errors.end_date}</FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date Of Site Survey"
                    value={values.interviewing_date}
                    onChange={(newValue) => {
                      setValues({ ...values, interviewing_date: newValue });
                    }}
                    error={errors.interviewing_date ? true : false}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errors.interviewing_date && (
                    <FormHelperText error>
                      {errors.interviewing_date}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Final Contract Award"
                    value={values.announcement_date}
                    onChange={(newValue) => {
                      setValues({ ...values, announcement_date: newValue });
                    }}
                    error={errors.announcement_date ? true : false}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errors.announcement_date && (
                    <FormHelperText error>
                      {errors.announcement_date}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="document_url"
                  placeholder="Upload Tender Document"
                  InputLabelProps={{ shrink: true }}
                  label="Upload Tender Document"
                  onChange={fileSelect}
                  error={errors.document_url ? true : false}
                  helperText={errors.document_url}
                  type="file"
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="ndo_url"
                  placeholder="Upload NDA Document"
                  InputLabelProps={{ shrink: true }}
                  label="Upload NDA Document"
                  onChange={ndaFileSelect}
                  error={errors.nda_url ? true : false}
                  helperText={errors.nda_url}
                  type="file"
                  accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 2, mt: 2 }}>
              {/* <FormControl sx={{ mb: 2 }} fullWidth>
                <TextField
                  fullWidth
                  id="picture"
                  placeholder="Picture"
                  InputLabelProps={{ shrink: true }}
                  label="Picture"
                  onChange={imgFileSelect}
                  error={errors.picture ? true : false}
                  helperText={errors.picture}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                />
              </FormControl> */}
              <RichTextEditor
                placeholder="Description"
                style={{ overflow: "scroll" }}
                value={textValue}
                onChange={handleChangeText}
              />
              {errors.body && (
                <FormHelperText error>{errors.body}</FormHelperText>
              )}
              <FormControl sx={{ my: 4 }} fullWidth>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />{" "}
                  <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                  <input
                    hidden
                    onChange={imgFileSelect}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    multiple
                    type="file"
                  />
                </Button>
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <ImageList
                sx={{ width: 280, height: 450, m: 2 }}
                cols={2}
                rowHeight={154}
              >
                {previews.length > 0 &&
                  previews.map((img, index) => (
                    <ImageListItem key={index}>
                      <img src={img} alt="" loading="lazy" />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleUpdate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Update
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default UpdateTender;
