import React, { useEffect, useState } from "react";
import authService from "../../../services/auth";
import { instanceToken } from "../../../utils/constant";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function Auths() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await authService.getAuths(instanceToken.token);
      setUsers(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!users) {
    return <em>Loading...</em>;
  }

  const handleUpdate = async (user) => {
    try {
      setLoading(true);
      const data = {
        active: !user.active,
      };
      const res = await authService.putAuth(instanceToken.token, data);
      setShowAlert({
        message: `Admin "${user.username}" have been ${
          user.active ? "Deactive" : "Activate"
        }.`,
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
      setUsers(users.map((u) => (u.id === user.id ? res.data : u)));
    } catch (error) {
      console.error(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>Admins</span>
        </Breadcrumbs>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow
                key={row.id}
                sx={
                  row.active
                    ? {
                        "&:last-child td, &:last-child th": { border: 0 },
                      }
                    : {
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: "#b1b1b1",
                      }
                }
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.role}</TableCell>
                <TableCell>{row?.createdAt.substring(0, 10)}</TableCell>
                <TableCell>
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    onClick={() => handleUpdate(row)}
                  >
                    {row.active ? "Deactive" : "Activate"}
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "absolute", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "absolute", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
}
