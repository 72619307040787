import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Grid,
  Box,
  Typography,
  CardContent,
  CardActions,
  Button,
  Breadcrumbs,
  // CardMedia,
  Alert,
  Modal,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Table,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import uploadService from "../../../services/upload";
import tenderService from "../../../services/tender";
import { instanceToken } from "../../../utils/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Tender = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tender, setTender] = useState(null);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openUD, setOpenUD] = useState(false);
  const [historyId, setHistoryId] = useState(null);

  useEffect(() => {
    if (!tender) {
      fetchTender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTender = async () => {
    try {
      const res = await tenderService.getTender(instanceToken.token, id);
      setTender(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  if (!tender) {
    return <em>Loading...</em>;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRemoveOpen = () => setOpenD(true);
  const handleRemoveClose = () => setOpenD(false);
  const handleRemoveUOpen = (id) => {
    setHistoryId(id);
    setOpenUD(true);
  };
  const handleRemoveUClose = () => setOpenUD(false);

  const tenderAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 3000);
  };

  const handleUpdate = async () => {
    try {
      const response = await tenderService.putTender(
        instanceToken.token,
        tender.id,
        {
          active: !tender.active,
        }
      );
      handleClose();
      tenderAlert(
        `This tender have been ${tender?.active ? "closed" : "reopened"}.`
      );
      updateData(response.data);
    } catch (error) {
      handleClose();
      tenderAlert("Error on sever", true);
    }
  };

  const handleDelete = async () => {
    try {
      await tenderService.deleteTender(instanceToken.token, tender.id);
      if (tender.pictures) {
        let imageArray = tender.pictures.split("||");
        imageArray = imageArray.map((image) =>
          image.substring(image.lastIndexOf("/") + 1)
        );
        await uploadService.deleteImages(instanceToken.token, {
          filenames: imageArray,
        });
      }
      let file_url = tender.document_url;
      if (file_url) {
        await deleteFile(file_url.substring(file_url.lastIndexOf("/") + 1));
      }
      let nda_url = tender.nda_url;
      if (nda_url) {
        await deleteFile(nda_url.substring(nda_url.lastIndexOf("/") + 1));
      }
      homeAlert("Tender have been deleted.");
      navigate("/tenders");
    } catch (error) {
      handleRemoveClose();
      let err = error.response;
      if (err.data.error.name === "SequelizeForeignKeyConstraintError") {
        tenderAlert(
          "Cannot delete tender because there are submitted user.",
          true
        );
        return;
      }
      tenderAlert("Error on sever", true);
    }
  };

  const handleUDelete = async () => {
    try {
      await tenderService.deleteUser(instanceToken.token, historyId);
      homeAlert("Submitted user have been deleted.");
      let users = tender.users.filter((u) => u.history.id !== historyId);
      setTender({ ...tender, users: users });
    } catch (error) {
      handleRemoveClose();
      tenderAlert("Error on sever", true);
    }
  };

  // const deleteImage = async (fileName) => {
  //   try {
  //     await uploadService.deleteImage(instanceToken.token, {
  //       imageName: fileName,
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const deleteFile = async (filename) => {
    try {
      await uploadService.deleteFile(instanceToken.token, {
        filename: filename,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateData = (data) => {
    setTender(data);
  };

  return (
    <>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/tenders">
            Tenders
          </Link>
          <Typography color="text.primary">Tender (ID - {id})</Typography>
        </Breadcrumbs>
      </div>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Card
          sx={{
            minWidth: "70vw",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
        >
          <div>
            {/* <CardMedia
              component="img"
              height="240"
              image={tender.picture}
              alt="picture"
            /> */}
            {tender.pictures && (
              <ImageList
                sx={{ width: 500, height: 250 }}
                cols={3}
                rowHeight={164}
              >
                {tender.pictures.split("||").map((img, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${img}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt=""
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}

            <CardContent sx={{ pr: 5 }}>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Name :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Project :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.topic}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Category :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.category}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Lowest Price :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.lowest_price}
                  </Typography>
                </Grid>
              </Grid> */}
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Start Date :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.start_date.substring(0, 10)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    End Date :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.end_date.substring(0, 10)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Interviewing Date :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.interviewing_date?.substring(0, 10)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Announcement Date :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.announcement_date?.substring(0, 10)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    Document :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.document_url ? (
                      <a
                        href={tender.document_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        open in new tag
                      </a>
                    ) : (
                      <span className="demo">there is no document</span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ m: 2 }} container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="text.secondary">
                    NAD :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2" color="text.secondary">
                    {tender.nda_url ? (
                      <a href={tender.nda_url} target="_blank" rel="noreferrer">
                        open in new tag
                      </a>
                    ) : (
                      <span className="demo">there is no document</span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </div>
          <Box
            sx={{
              p: 2,
              bgcolor: "#f7f7f5",
              borderRadius: 2,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: tender.body }}></div>
          </Box>
          <CardActions sx={{ gridColumn: "1/3", justifyContent: "end" }}>
            <Button size="small" onClick={handleOpen}>{`${
              tender?.active ? "close" : "reopen"
            } tender`}</Button>
            <Button
              size="small"
              onClick={() => navigate(`/updateTender/${tender.id}`)}
            >
              Edit
            </Button>
            <Button size="small" color="error" onClick={handleRemoveOpen}>
              Delete
            </Button>
          </CardActions>
        </Card>
        {tender.users ? (
          <TableContainer sx={{ mt: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tender.users.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => handleRemoveUOpen(row.history.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="text.secondary">
            There is no registered user
          </Typography>
        )}
      </Container>
      <Modal
        open={openUD}
        onClose={handleRemoveUClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleRemoveUClose}>
              Cancel
            </Button>
            <Button onClick={handleUDelete}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to {tender?.active ? "close" : "reopen"} this
            tender?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleUpdate}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openD}
        onClose={handleRemoveClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to delete?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleRemoveClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Tender;
