import React, { useEffect, useState, createContext, Suspense } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../layouts/Header";
import SideBar from "../layouts/SideBar";
import { Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { initToken } from "../utils/constant";
import authService from "../services/auth";

import Tenders from "./auth/tender/Tenders";
import Tender from "./auth/tender/Tender";
import CreateTender from "./auth/tender/CreateTender";
import UpdateTender from "./auth/tender/UpdateTender";
import Users from "./auth/user/Users";
import Services from "./auth/servicePost/Services";
import Projects from "./auth/projectPost/Projects";
import News from "./auth/newsPost/News";
import CreateService from "./auth/servicePost/CreateService";
import CreateProject from "./auth/projectPost/CreateProject";
import CreateNews from "./auth/newsPost/CreateNews";
import Project from "./auth/projectPost/Project";
import Service from "./auth/servicePost/Service";
import UpdateProject from "./auth/projectPost/UpdateProject";
import UpdateService from "./auth/servicePost/UpdateService";
import Contacts from "./auth/contact/Contacts";
import Banners from "./auth/banner/Banners";
import Auths from "./auth/auths/Auths";
import CreateAuth from "./auth/auths/CreateAuth";
import Dashboard from "./auth/Dashboard";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Home() {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [open, setOpen] = useState(false);

  const AuthContext = createContext();
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  useEffect(() => {
    const loggedJSON = window.localStorage.getItem("Vesta_loggedAdmin");
    if (loggedJSON) {
      const parsedJSON = JSON.parse(loggedJSON);
      fetchAuth(parsedJSON.token).then(() => {
        initToken(parsedJSON.token, parsedJSON.role);
      });
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAuth = async (token) => {
    try {
      const res = await authService.getAuth(token);
      setAuth(res.data);
    } catch (error) {
      if (
        error?.response.data.error === "token invalid" ||
        error?.response.data.error === "token expired"
      ) {
        navigate("/login");
      }
    }
  };

  const updateAuth = (data) => {
    setAuth(data);
  };

  const homeAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 4000);
  };

  if (!auth) {
    return <div>Loading...</div>;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        user={auth}
        updateUser={updateAuth}
        homeAlert={homeAlert}
      />
      <SideBar handleDrawerClose={handleDrawerClose} open={open} auth={auth} />
      <Main open={open}>
        <DrawerHeader />
        <Suspense fallback={<div>Loading...</div>}>
          <AuthContext.Provider value={auth}>
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    user={auth}
                    updateUser={updateAuth}
                    homeAlert={homeAlert}
                  />
                }
              />
              {auth.role === "tender" && (
                <>
                  <Route path="/tenders" element={<Tenders />} />
                  <Route
                    path="/tender/:id"
                    element={<Tender homeAlert={homeAlert} />}
                  />
                  <Route path="/createTender" element={<CreateTender />} />
                  <Route path="/updateTender/:id" element={<UpdateTender />} />
                  <Route path="/admins" element={<Auths />} />
                  <Route path="/createAdmin" element={<CreateAuth />} />
                </>
              )}

              {auth.role === "website" && (
                <>
                  <Route path="/services" element={<Services />} />
                  <Route path="/createService" element={<CreateService />} />
                  <Route
                    path="/service/:id"
                    element={<Service homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/updateService/:id"
                    element={<UpdateService />}
                  />
                  <Route path="/projects" element={<Projects />} />
                  <Route
                    path="/project/:id"
                    element={<Project homeAlert={homeAlert} />}
                  />
                  <Route path="/createProject" element={<CreateProject />} />
                  <Route
                    path="/updateProject/:id"
                    element={<UpdateProject />}
                  />
                  <Route
                    path="/news"
                    element={<News homeAlert={homeAlert} />}
                  />
                  <Route path="/createNews" element={<CreateNews />} />
                  <Route
                    path="/contacts"
                    element={<Contacts homeAlert={homeAlert} />}
                  />
                  <Route
                    path="/banners"
                    element={<Banners homeAlert={homeAlert} />}
                  />
                </>
              )}
              <Route path="/users" element={<Users />} />
              <Route path="/*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </AuthContext.Provider>
        </Suspense>
      </Main>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </Box>
  );
}
