import React, { useState } from "react";
import { Link } from "react-router-dom";
import serviceService from "../../../services/service";
import uploadService from "../../../services/upload";
import {
  Card,
  CardContent,
  CardActions,
  Box,
  Breadcrumbs,
  FormControl,
  TextField,
  FormHelperText,
  Alert,
  Button,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RichTextEditor from "react-rte";
import { instanceToken, BACKEND_URL } from "../../../utils/constant";

const imgFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const CreateService = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isImageChange, setIsImageChange] = useState(false);

  const [values, setValues] = useState({
    title: "",
    description: "",
    pictures: "",
    topic: "",
    titleMm: "",
    descriptionMm: "",
    topicMm: "",
  });
  const [errors, setErrors] = useState({});
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMm, setTextValueMm] = useState(
    RichTextEditor.createEmptyValue()
  );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeText = (value) => {
    setTextValue(value);
    setValues({ ...values, description: value.toString("html") });
  };
  const handleChangeTextMm = (value) => {
    setTextValueMm(value);
    setValues({ ...values, descriptionMm: value.toString("html") });
  };

  const imgFileSelect = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const fileList = e.target.files;
      const preview = [];
      const files = [];
      for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
        const img = fileList[i];
        if (!imgFileTypes.includes(img.type)) {
          setErrors({
            ...errors,
            profile: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
          });
          return;
        }
        if (img.size > 10485760) {
          setErrors({
            ...errors,
            profile: "Image file size must be smaller than 10MB.",
          });
          return;
        }
        preview.push(URL.createObjectURL(img));
        setIsImageChange(true);
        files.push(img);
      }
      setPreviews(preview);
      setImageFiles(files);
    }
  };

  const handleMultiImgUpload = async (formData) => {
    try {
      const response = await uploadService.uploadImages(
        instanceToken.token,
        formData
      );
      return response.data.filenames;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreate = async () => {
    setErrors({});
    let err = {};
    if (!values.title) {
      err.title = "Title field is required";
    }
    if (!values.description) {
      err.description = "Description field is required";
    }
    if (!values.topic) {
      err.topic = "Topic field is required";
    }
    if (!values.titleMm) {
      err.titleMm = "Title (Myanmar) field is required";
    }
    if (!values.descriptionMm) {
      err.descriptionMm = "Description (Myanmar) field is required";
    }
    if (!values.topicMm) {
      err.topicMm = "Topic (Myanmar) field is required";
    }
    if (imageFiles.length <= 0) {
      err.pictures = "Please upload some pictures";
    }
    if (Object.getOwnPropertyNames(err).length > 0) {
      setErrors({ ...err });
      return;
    }
    try {
      setLoading(true);
      let data = values;

      if (isImageChange) {
        const formData = new FormData();
        for (let i = 0, numFiles = imageFiles.length; i < numFiles; i++) {
          const img = imageFiles[i];
          formData.append("images", img);
        }
        let filenames = await handleMultiImgUpload(formData);
        filenames = filenames.map(
          (filename) => `${BACKEND_URL}/static/images/${filename}`
        );
        const names = filenames.join("||");
        data = { ...data, pictures: names };
      }

      await serviceService.postService(instanceToken.token, data);

      setValues({
        title: "",
        description: "",
        pictures: "",
        topic: "",
        titleMm: "",
        descriptionMm: "",
        topicMm: "",
      });
      setPreviews([]);
      setShowAlert({
        message: "New Sevice have been created.",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
      setTextValue(RichTextEditor.createEmptyValue());
      setTextValueMm(RichTextEditor.createEmptyValue());
    } catch (error) {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/services">Services</Link>
          <span>Create</span>
        </Breadcrumbs>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: 300 }}>
          <CardContent sx={{ display: "flex" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="title"
                  label="Title"
                  value={values.title}
                  onChange={handleChange("title")}
                  error={errors.title ? true : false}
                  helperText={errors.title}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="titleMm"
                  label="Title (Myanmar)"
                  value={values.titleMm}
                  onChange={handleChange("titleMm")}
                  error={errors.titleMm ? true : false}
                  helperText={errors.titleMm}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="topic"
                  label="Topic"
                  multiline
                  value={values.topic}
                  onChange={handleChange("topic")}
                  error={errors.topic ? true : false}
                  helperText={errors.topic}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <TextField
                  id="topicMm"
                  label="Topic (Myanmar)"
                  multiline
                  value={values.topicMm}
                  onChange={handleChange("topicMm")}
                  error={errors.topicMm ? true : false}
                  helperText={errors.topicMm}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  size="large"
                  sx={{ p: 2 }}
                >
                  <PhotoCamera />
                  {errors.pictures ? (
                    <Typography sx={{ ml: 1, color: "red" }}>
                      {errors.pictures}
                    </Typography>
                  ) : (
                    <Typography sx={{ ml: 1 }}>Upload Pictures</Typography>
                  )}
                  <input
                    hidden
                    onChange={imgFileSelect}
                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                    multiple
                    type="file"
                  />
                </Button>
              </FormControl>
              <ImageList
                sx={{ width: 500, height: 250, m: 2 }}
                cols={3}
                rowHeight={164}
              >
                {previews.length > 0 &&
                  previews.map((img, index) => (
                    <ImageListItem key={index}>
                      <img src={img} alt="" loading="lazy" />
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
            <Box sx={{ flex: 1, mt: 2 }}>
              <Box>
                <RichTextEditor
                  style={{ overflow: "scroll" }}
                  value={textValue}
                  onChange={handleChangeText}
                  toolbarConfig={toolbarConfig}
                  placeholder="Description"
                />
                {errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
              </Box>
              <Box>
                <RichTextEditor
                  style={{ overflow: "scroll" }}
                  value={textValueMm}
                  onChange={handleChangeTextMm}
                  toolbarConfig={toolbarConfig}
                  placeholder="Description (Myanmar)"
                />
                {errors.descriptionMm && (
                  <FormHelperText error>{errors.descriptionMm}</FormHelperText>
                )}
              </Box>
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handleCreate}
              sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
            >
              Create
            </LoadingButton>
          </CardActions>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default CreateService;
