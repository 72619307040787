import axios from "axios";
import { BACKEND_URL } from "../utils/constant";

const getTenderData = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(`${BACKEND_URL}/api/dashboards`, config);
  return response;
};

const getRecentlyAppliers = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/dashboard/recentlyAppliers`,
    config
  );
  return response;
};

const getWebsiteData = async (token) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.get(
    `${BACKEND_URL}/api/dashboards/website`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTenderData,
  getWebsiteData,
  getRecentlyAppliers,
};
