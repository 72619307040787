import axios from "axios";
import { BACKEND_URL } from "../utils/constant";
// const BACKEND_URL = "http://localhost:3001";
// const BACKEND_URL = "http://157.230.242.167:3001";

const getBanners = async () => {
  const response = await axios.get(`${BACKEND_URL}/api/banners`);
  return response;
};

const postBanner = async (token, data) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.post(`${BACKEND_URL}/api/banners`, data, config);
  return response;
};

const deleteBanner = async (token, id) => {
  const config = {
    headers: { authorization: `Bearer ${token}` },
  };
  const response = await axios.delete(
    `${BACKEND_URL}/api/banners/${id}`,
    config
  );
  return response;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBanners,
  deleteBanner,
  postBanner,
};
