import React, { useEffect, useState } from "react";
import newsService from "../../../services/news";
import { instanceToken } from "../../../utils/constant";
import uploadService from "../../../services/upload";

import {
  TablePagination,
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  Modal,
  CardMedia,
} from "@mui/material";
import CreateNews from "./CreateNews";
import UpdateNews from "./UpdateNews";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  // height: "100vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function News({ homeAlert }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [news, setNews] = useState(null);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [openU, setOpenU] = useState(false);
  const [openR, setOpenR] = useState(false);

  useEffect(() => {
    fetchNews(rowsPerPage, offset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, rowsPerPage]);

  const fetchNews = async (limit, offset) => {
    try {
      const res = await newsService.getNews(instanceToken.token, limit, offset);
      parse(res.data);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const parse = (data) => {
    setNews(data.news);
    setCount(data.count);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!news) {
    return <em>Loading...</em>;
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenU = (data) => {
    setData(data);
    setOpenU(true);
  };
  const handleCloseU = () => {
    setOpenU(false);
  };

  const handleOpenR = (data) => {
    setData(data);
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const handleRemove = async (data) => {
    try {
      if (data.picture) {
        const filename = data.picture.substring(
          data.picture.lastIndexOf("/") + 1
        );
        await uploadService.deleteImage(instanceToken.token, {
          filename: filename,
        });
      }
      await newsService.deleteNews(instanceToken.token, data.id);
      homeAlert("News have been removed.", false);
    } catch (error) {
      console.log(error);
      homeAlert("Error on server!", true);
    }
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <span>News</span>
        </Breadcrumbs>
      </div>
      <Button sx={{ my: 3 }} variant="contained" onClick={handleOpen}>
        Create News
      </Button>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        {news.map((row, index) => (
          <Card sx={{ maxWidth: 385 }} key={index}>
            <CardMedia component="img" height="194" image={row.picture} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {row.title}
              </Typography>
              <div
                style={{
                  height: 80,
                  width: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
                dangerouslySetInnerHTML={{ __html: row.description }}
              ></div>
            </CardContent>
            <CardActions>
              <Button
                color="error"
                size="small"
                onClick={() => handleOpenR(row)}
              >
                Remove
              </Button>
              <Button
                color="primary"
                size="small"
                onClick={() => handleOpenU(row)}
              >
                Edit
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove it?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={() => handleRemove(data)}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateNews homeAlert={homeAlert} handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal
        open={openU}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UpdateNews
            news={data}
            homeAlert={homeAlert}
            handleClose={handleCloseU}
          />
        </Box>
      </Modal>
    </>
  );
}
