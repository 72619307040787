import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  FormHelperText,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import authService from "../../../services/auth";
import { instanceToken } from "../../../utils/constant";
import { Link } from "react-router-dom";

const CreateAuth = () => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({
    username: "",
    password: "",
    conPassword: "",
    role: "",
    showPassword: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const updateUser = async (payload) => {
    try {
      await authService.postAuth(instanceToken.token, payload);
      setValues({
        username: "",
        password: "",
        conPassword: "",
        role: "",
        showPassword: false,
      });
      setShowAlert({
        message: "New Admin have been created.",
        isError: false,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } catch (error) {
      console.log("error : ", error);
      setShowAlert({
        message: "Sever Error",
        isError: true,
      });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 4000);
    } finally {
      setLoading(false);
      setErrors({});
    }
  };

  const handleCreate = () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.username) {
      errorObject.username = "Usename field is required";
      isErrorExit = true;
    }
    if (!values.role) {
      errorObject.role = "Role field is required";
      isErrorExit = true;
    }
    if (!values.password) {
      errorObject.password = "Password field is required";
      isErrorExit = true;
    }
    if (!values.password) {
      errorObject.password = "Password field is required";
      isErrorExit = true;
    }
    if (values.password) {
      if (values.password !== values.conPassword) {
        errorObject.password = "Password & Confirm Password must be the same";
        errorObject.conPassword =
          "Password & Confirm Password must be the same";
        isErrorExit = true;
      }
    }
    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    updateUser(values);
  };

  return (
    <>
      <div role="presentation" style={{ marginBlockEnd: "10px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/admins">Admins</Link>
          <span>Create</span>
        </Breadcrumbs>
      </div>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ minWidth: 300 }}>
          <CardContent sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl sx={{ m: 2, width: "300px" }} variant="outlined">
                <TextField
                  id="username"
                  label="Username"
                  value={values.username}
                  onChange={handleChange("username")}
                  error={errors.username ? true : false}
                  helperText={errors.username}
                />
              </FormControl>
              <FormControl sx={{ m: 2, width: "300px" }} variant="outlined">
                <TextField
                  id="password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Password"
                  error={errors.password ? true : false}
                  helperText={errors.password}
                />
              </FormControl>
              <FormControl sx={{ m: 2, width: "300px" }} variant="outlined">
                <TextField
                  id="conPassword"
                  type={values.showPassword ? "text" : "password"}
                  value={values.conPassword}
                  onChange={handleChange("conPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Confirm Password"
                  error={errors.conPassword ? true : false}
                  helperText={errors.conPassword}
                />
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <InputLabel id="role">Role</InputLabel>
                <Select
                  labelId="role"
                  value={values.role}
                  label="Role"
                  onChange={handleChange("role")}
                  error={errors.role ? true : false}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="tender">Tender</MenuItem>
                  <MenuItem value="website">Website</MenuItem>
                </Select>
                {errors.role && (
                  <FormHelperText error>{errors.role}</FormHelperText>
                )}
              </FormControl>
              <FormControl sx={{ m: 2 }} variant="outlined">
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={handleCreate}
                  sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
                >
                  Create
                </LoadingButton>
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default CreateAuth;
